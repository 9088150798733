@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-VariableFont_wght.ttf") format("truetype");
  font-weight: 200 500;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Manrope", Arial, sans-serif;
  font-weight: 500;
  background-color: black;
}

.App {
  min-height: 100vh;
}

a {
  text-decoration: none;
}

.hover-path:hover svg path {
  stroke: black;
}

.hover-path-white:hover svg path {
  stroke: white;
}
